export const keyBy = (key,value) => {
	const r = {}
	for (let v of value){
		r[v[key]] = v
	}
	return r
}

export const uniq = arr => [...new Set(arr)]

export const doOnce = (fn) => {
	let done = false
	return (...args) => {
		if (!done){
			done = true
			fn(...args)
		}
	}
}

export const getParentWindow = () => window.parent;

export const isIe = (navigator.userAgent.indexOf('MSIE') !== -1 ||
	navigator.appVersion.indexOf('Trident/') > -1);

export const supportsAnimations = !isIe;

export const canHover = () => window.matchMedia('(hover: hover)').matches;

export const toArray = thing => [].slice.call(thing)

export const partition = (arr, predicate) => {
	const passed = [];
	const failed = [];
	for (const item of arr) {
		(predicate(item) ? passed : failed).push(item);
	}
	return [passed, failed];
}

export const clamp = (num, min, max) => {
	if (num > max) {
			return max
	}
	if (num < min) {
			return min
	}

	return num
}

// From https://googlechrome.github.io/samples/service-worker/post-message/
export function sendMessage(recipient, message, origin) {
	return new Promise(function(resolve, reject) {
		var messageChannel = new MessageChannel();
		messageChannel.port1.onmessage = function(event) {
			if (event.data.error) {
				reject(event.data.error);
			} else {
				resolve(event.data);
			}
			messageChannel.port1.close();
			messageChannel.port2.close();
		};
		messageChannel.port1.onmessageerror = e => window.Rollbar.error(e);
		messageChannel.port2.onmessageerror = e => window.Rollbar.error(e);
		recipient.postMessage(message, origin, [messageChannel.port2])
	});
}

// from https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/navigator.js
export const getNavigatorLanguage = () => {
	let found = [];

	if (typeof navigator !== 'undefined') {
		if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
			for (let i=0; i < navigator.languages.length; i++) {
				found.push(navigator.languages[i]);
			}
		}
		if (navigator.userLanguage) {
			found.push(navigator.userLanguage);
		}
		if (navigator.language) {
			found.push(navigator.language);
		}
	}

	return found[0]
}

export const noop = () => {}