import { log } from './utils'
import { toArray } from 'utils'
import { trackAutoblock } from './config'
import {
	copyElementAttrs,
	onDOMChange,
	OUR_MIME_TYPE,
	OUR_MIME_TYPE_SELECTOR,
	OUR_POLICY_SLUG_TAG,
	BLOCKED_TYPE_DATA_KEY,
} from './domCompat';
import {
	PLACEHOLDER_DATA_KEY,
	PLACEHOLDER_PARAMS_DATA_KEY
} from 'modules/placeholders/dom'
import { shouldBlockNode, getConfigForNode } from './filter'
import applyMonkeypatches from './monkeypatches'


// Quarantine existing script tags by converting them into an mtmTag
function blockScript (script) {
	script.dataset[BLOCKED_TYPE_DATA_KEY] = script.getAttribute('type') || 'text/javascript';
	script.setAttribute('type', OUR_MIME_TYPE);

	return script;
}

// Quarantine non-script tags by wrapping them in an mtmTag
function blockNode (node) {
	const mtmTag = document.createElement('script')

	mtmTag.setAttribute('type', OUR_MIME_TYPE);
	if (node.parentNode) {
		node.parentNode.replaceChild(mtmTag, node)
	} else {
		document.body.appendChild(mtmTag);
	}

	copyElementAttrs(mtmTag, node, ['style', 'class', 'width', 'height']);
	// Cannot appendChild here, or that will cause the browser to
	// fetch the remote resource if it's an iframe.
	mtmTag.text = node.outerHTML;

	return mtmTag;
}

export function block (node, ctx) {
	if (node.closest(OUR_MIME_TYPE_SELECTOR)) return node;
	const config = getConfigForNode(node);
	// Thi should never happen, but it is sometimes for reasons unbeknowsnt to me
	if (!config) return;

	log(`%cblocking - ${ctx}:`, 'background: red; color: black;',node.outerHTML)

	const mtmTag = node.nodeName === 'SCRIPT'
		? blockScript(node)
		: blockNode(node);

	mtmTag.mtmBlockedNode = true;
	trackAutoblock(config)

	const {
		policySlug,
		placeholder,
	} = config;

	mtmTag.setAttribute(OUR_POLICY_SLUG_TAG, policySlug);

	// If the config explicitly asks not to be placeheld, just block.
	if (!placeholder) return mtmTag;

	const {
		source,
		params,
	} = placeholder;

	mtmTag.dataset[PLACEHOLDER_DATA_KEY] = source || '/generic.html';

	if (params) {
		mtmTag.dataset[PLACEHOLDER_PARAMS_DATA_KEY] = Object.keys(params).reduce((paramsStr, key) => {
			const value = params[key];
			return `${paramsStr}\n${key}=${typeof value === 'string' ? value : JSON.stringify(value)}`;
		}, '');
	}
	return mtmTag;
}

function mayBlockAddedNodes(changes) {
	const nodesToBlock = changes.reduce((nodes, change) => {
		switch (change.type) {
			case 'childList':
				const nodesToAdd = toArray(change.addedNodes)
					.filter(
						node => node.nodeType === 1 && // only elements
						shouldBlockNode(node, 'onDomChange')
					);
				const nodesToRemove = toArray(change.removedNodes);
				return [...nodes, ...nodesToAdd].filter(node => nodesToRemove.indexOf(node) === -1)
			default:
				return nodes;
		}
	}, []);
	nodesToBlock.length && nodesToBlock.forEach((node) => block(node, 'onDomChange'));
}

export default function startAutoblocking() {
  applyMonkeypatches()
  onDOMChange(mayBlockAddedNodes, {
	targetNode: document.documentElement,
    observerConfig: {
      childList: true,
      subtree: true,
    },
  })
}
