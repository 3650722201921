function emergencyReportError(error, context) {
	if (isDev) return console.error(error, context);
	var xmlhttp = new XMLHttpRequest()
	xmlhttp.open('POST', 'https://api.rollbar.com/api/1/item/')
	xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
	xmlhttp.setRequestHeader(
		'X-Rollbar-Access-Token',
		'744da5bc66854e9f8e0728eee70c0992'
	)
	xmlhttp.send(
		JSON.stringify({
			access_token: '744da5bc66854e9f8e0728eee70c0992',
			data: {
				code_version: process.env.SERVICE_TAG,
				context: window.location.origin,
				environment: process.env.ROLLBAR_ENV,
				platform: 'browser',
				framework: 'browser-js',
				language: 'javascript',
				request: {
					url: window.location.origin,
					query_string: 'not-sent',
					user_ip: null,
				},
				body: {
					trace: {
						frames: [],
						exception: {
							class: error.name,
							message: context + ': ' + error.message,
							stack: error.stack,
							description: error.toString(),
						},
					},
				},
				level: 'critical',
				client: {
					javascript: {
						browser: navigator.userAgent,
						code_version: process.env.SERVICE_TAG,
					},
				},
				title: context + ': ' + error.message,
				custom: {
					is_in_iframe: !!window.frameElement,
				}
			},
		})
	)
}

export default function makeTry(fn, context = 'Bootfail') {
	return function() {
		try {
			fn.apply(null, arguments)
		} catch (e) {
			if(Math.random() > 0.98) {
				// Only publish some errors
				emergencyReportError(e, context)
			}
		}
	}
}
