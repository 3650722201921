
import {
  getElementAttrs,
} from 'sdk/dom';

export {
  onDOMChange,
	OUR_MIME_TYPE,
	OUR_MIME_TYPE_SELECTOR,
	OUR_POLICY_SLUG_TAG,
	BLOCKED_SRC_DATA_KEY,
	BLOCKED_TYPE_DATA_KEY,
} from 'sdk/dom';
/**
 * This is a compatibility layer for sdk/dom that does not require any polyfills.
 * Any autoblocking code should use DOM utils from this file instead of sdk/dom.
 */

export function copyElementAttrs(from, to, whitelist) {
	getElementAttrs(from)
		.forEach(({ name, value }) => {
			if (whitelist && whitelist.indexOf(name) === -1) return;
			to.setAttribute(name, value)
		})
}

export const getOrigin = (url) => {
	const a = document.createElement('a')
	a.href=url
	try {
		return new URL(a.href).origin
	} catch (e){
		const doc = document.implementation.createHTMLDocument('');
		const anchor = doc.createElement('a');
		anchor.href = url;
		doc.body.appendChild(anchor);
		return `${anchor.protocol}//${anchor.hostname}:${anchor.port}`
	}
}

