import { getParentWindow, toArray, uniq } from '../utils'

export const OUR_MIME_TYPE = 'text/x-metomic'
export const OUR_MIME_TYPE_SELECTOR = `[type="${OUR_MIME_TYPE}"]`;
export const OUR_POLICY_SLUG_TAG = 'data-micropolicy'

const OUR_POLICY_SLUG_TAG_OLD = 'data-metomic-policy'

export const BLOCKED_TYPE_DATA_KEY = 'blockedType';
export const BLOCKED_SRC_DATA_KEY = 'blockedSrc';

export const getParentDocument = () => getParentWindow().document;

// Un-monkey-patched document.createElement, for use when we need to modify the
// host page. Because the prototype chain does not cross iframe contexts,
// `document.Element !== getParentDocument().Element`.
// Therefore it is important to know which `createElement` to use:
// - When creating an element that will be rendered in the parent
//   document always use this method.
// - When creating an element that will be rendered inside the metomic iframe,
//   use regular `document.createElement`.
export const createElement = (...args) => HTMLDocument.prototype.createElement.call(getParentDocument(), args)

export const querySelectorAll = selector => toArray(getParentDocument().querySelectorAll(selector))

export const getScriptSlug = script => {
	return script.getAttribute(OUR_POLICY_SLUG_TAG) || script.getAttribute(OUR_POLICY_SLUG_TAG_OLD)
}

export const getUniqSlugsFromNodes = (nodes) => uniq(nodes.map(getScriptSlug));

export const getBlockedElements = () => querySelectorAll(OUR_MIME_TYPE_SELECTOR);

export const getElementAttrs = ele => (
	toArray(ele.attributes)
		.map(attr => ({
			name: attr.name,
			value: attr.value
		}))
)

export function copyElementAttrs(from, to, whitelist) {
	getElementAttrs(from)
		.forEach(({ name, value }) => {
			if (whitelist && !whitelist.includes(name)) return;
			to.setAttribute(name, value)
		})
}

export const cloneScript = (script) => {
	const clone = createElement('script')
	copyElementAttrs(script, clone);
	if (script.text) clone.text = script.text
	return clone
}

export const getOrigin = (url) => {
	const a = createElement('a')
	a.href=url
	try{
		return new URL(a.href).origin
	}catch (e){
		return
	}
}

export const onReady = (listener)=> {
	const parentWindow = getParentWindow()
	if (getParentDocument().readyState !== 'loading') {
		listener()
	} else if (parentWindow.attachEvent) {
		parentWindow.attachEvent('DOMContentLoaded', listener)
	} else {
		parentWindow.addEventListener('DOMContentLoaded', listener, false)
	}
}

export const onDOMChange = (func, options = {}) => {
	if (typeof MutationObserver !== 'function') return;

	const {
		targetNode = getParentDocument().documentElement,
		observerConfig = {
			attributes: true,
			childList: true,
			characterData: true,
			subtree: true,
		}
	} = options;
	const observer = new MutationObserver(func)

	observer.observe(targetNode, observerConfig);
	return observer;
}