import { log } from './utils'
import { shouldBlockRequest, shouldBlockTag } from './filter'
import { block } from './autoblocking'
import { OUR_MIME_TYPE } from './domCompat';

//TODO: ravenjs instead if doing XHR/fetch filtering
const monkeyPatchFetch = () => {
	// should resolve when consent is given
	if (typeof fetch === 'function') {
		const backupFetch = fetch
		window.fetch = function(dest) {
			log('[fetch]','check starting', dest)
			if (shouldBlockRequest(dest, 'fetch')) {
				log('[fetch]','blocked', dest)
				throw new Error('cannot make request to third party service without user consent')
			}

			log('[fetch]','allowed', dest)

			return backupFetch.apply(this, arguments)
		}
	}
}

const monkeyPatchXHR = () => {
	// should resolve when consent is given
	const backupOpen = XMLHttpRequest.prototype.open
	XMLHttpRequest.prototype.open = function(method, dest) {
	   	if (shouldBlockRequest(dest, 'xhr')) {
		   throw new Error('cannot make request to third party service without user consent')
	   	}

	   backupOpen.apply(this, arguments)
   }
}

const monkeyPatchCreateElement = () => {
	const createElement = document.createElement.bind(document)
	document.createElement = (tagName) => {
		const ele = createElement(tagName)
		const originalSetAttribute = ele.setAttribute.bind(ele)

		if (tagName.toLowerCase() === 'script') {

			Object.defineProperties(ele, {
				type: {
					set(type) {
						const src = this.src;
						const shouldBlock = src && shouldBlockRequest(src, 'script type setter');
						originalSetAttribute('type', shouldBlock ? OUR_MIME_TYPE : type);
						return true
					}
				},
				src: {
					get() {
						return ele.getAttribute('src');
					},
					set(src) {
						if (shouldBlockRequest(src, 'script src setter')) {
							originalSetAttribute('src', src)
							block(ele, 'script src setter')
						} else {
							originalSetAttribute('src', src)
						}
						return true
					}
				}
			})

			ele.setAttribute = function(name, value) {
				// Use the monkeypatched setters
				if (name === 'type' || name === 'src') {
					ele[name] = value
					return
				}
				originalSetAttribute(name,value)
			}
		} else if (shouldBlockTag(tagName)) {
			Object.defineProperties(ele, {
				src: {
					get() {
						return this.getAttribute('src')
					},
					set(value) {
						if (shouldBlockRequest(value, 'spicy src setter')) {
							block(ele, 'spicy src setter')
						}
						originalSetAttribute('src', value)
						return true
					}
				}
			})

			ele.setAttribute = function(name, value) {
				// Use patched setter
				if (name === 'src') {
					ele[name] = value
					return
				}
				originalSetAttribute(name,value)
			}
		}

		return ele
	}
}

const addEventListenerBackup = window.addEventListener
export const addEventListener = (a,b,c) => addEventListenerBackup.bind(window)(a,b,c)

const daddEventListenerBackup = document.addEventListener
export const daddEventListener = (a,b,c) => daddEventListenerBackup.bind(document)(a,b,c)


// TODO: IE
const monkeyPatchWindowOnload = () => {
	// const wae = window.attachEvent
	document.addEventListener = (type, listener, options) => {
		if (type === 'DOMContentLoaded') {
			if (document.readyState.includes('load')) {
				listener()
				return
			}
		}
		daddEventListener(type, listener, options)
	}

	window.addEventListener = (type, listener, options) => {
		if (type === 'load') {
			if (document.readyState.includes('load')) {
				listener()
				return
			}
		}
		addEventListener(type, listener, options)
	}
}

const monkeyPatch = () => {
	// monkeyPatchFetch()
	// monkeyPatchXHR()
	// monkeyPatchWindowOnload()
	monkeyPatchCreateElement()
}

export default monkeyPatch