import makeTry from './makeTry'

const dcl = 'DOMContentLoaded'

function attach(fn, win) {
	if (win.document?.readyState !== 'loading') return fn()

	// This will only be true for the host window--the confirmic iframe will
	// not have any navigation entries.
	const navigationPerfEntry =
		win.performance?.getEntriesByType?.('navigation')[0]

	// This is a degenerate case that will happen when there's a script that
	// 1. defers executing embed.js after DCL, and
	// 2. monkey patches document.readyState to be `loading`
	// In particular this arose from finding a website with
	// [Rocket Loader](https://support.cloudflare.com/hc/en-us/articles/200168056-What-does-Rocket-Loader-do-)
	const hasDCLFired =
		navigationPerfEntry?.domContentLoadedEventStart > 0 ||
		win.performance?.timing?.domContentLoadedEventStart > 0 // Legacy Performance API
	if (hasDCLFired) {
		return fn()
	} else if (win.attachEvent) {
		win.attachEvent(dcl, fn)
	} else if (win.addEventListener) {
		win.addEventListener(dcl, fn, false)
	} else {
		// What?! We once encountered a bunch of errors that document was
		// not found, and wound up tracing it to the fact that the window
		// being passed in here was not an instance of Window, but of Object.
	}
}

/**
 * Loads the bundle inside an iframe so that any polyfills and styles
 * it pulls in don't affect the host window
 */
export default function bootstrap() {
	const iframe = document.createElement('iframe')
	iframe.setAttribute('id', 'confirmic-iframe')
	iframe.src = 'about:blank'
	iframe.setAttribute('title', 'confirmic-iframe-bootstrap')

	const scriptEl = document.createElement('script')
	scriptEl.type = 'text/javascript'
	scriptEl.charset = 'utf-8'
	scriptEl.async = true
	scriptEl.crossOrigin = 'anonymous'
	iframe.setAttribute('title', 'confirmic-iframe-bootstrap')

	scriptEl.onerror = makeTry((event) => {
		// Rate limit
		if (Math.random() >= 0.99) {
			throw new Error(`Could not load: ${event.target.src}`)
		}
	}, 'Boot script onerror')
	scriptEl.src = process.env.DEPLOYED_BUNDLE_URL

	// Kick off the load
	iframe.onload = makeTry(() => {
		const iframeWindow = iframe.contentWindow
		try {
			iframeWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__ =
				window.__REACT_DEVTOOLS_GLOBAL_HOOK__
		} catch (e) {
			// Ignore cross-domain errors
		}
		attach(() => iframeWindow.document.body.appendChild(scriptEl), iframeWindow)
	}, 'Boot iframe onload')

	attach(() => document.head.appendChild(iframe), window)
}
