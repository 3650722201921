/**
 * v1 and v2 snippets load the embed asynchronously, which means that if users
 * call `window.Metomic()` before the script is executed, there needs to be a
 * queueing mechanism to buffer those calls. When the bundle is loaded, it then
 * calls `dequeueMetomicActionBuffer` to override Metomic.q.push to call out to
 * the actions directly.
 */

export function setupConfirmicActionBuffer(win) {
  // why? because this _might_ have already been set up by an old embed snippet
  // In the v1 snippet, Metomic is an object
  // In the v2 snippet Metomic is a queueing function
  // In the v3 snippet there is no queuing function since the snippet is blocking
  if (typeof win.Metomic === 'object') {
    win.Metomic.snippetVersion = 1;
  } else if (typeof win.Metomic === 'function') {
    win.Metomic.snippetVersion = 2;
  } else {
    win.Metomic = { snippetVersion: 3 };
  }

  // Normalise all versions into a queuing function
  if (typeof win.Metomic !== 'function') {
    const q = []
    function Metomic(){ q.push(arguments) }
    Metomic.q = q

    // Copy over any leftover keys from a v1/v2 snippet object for backwards compatibilty
    Object.keys(win.Metomic || {}).forEach((key) => Metomic[key] = win.Metomic[key]);
    win.Metomic = Metomic;
    // Keep a reference to Metomic for pre-Metomic users who are using it directly
    win.Confirmic = Metomic;
  }

  return win.Metomic;
}

export function flushConfirmicActionBuffer (Metomic, callback) {
  while (Metomic.q.length) callback(...Metomic.q.shift())
}

// Clobber the push method to no longer enqueue, but to run the callback
// immediately
export function disableConfirmicActionBuffer(Metomic, callback) {
  Metomic.q.push = (args) => callback(...args)
  flushConfirmicActionBuffer(Metomic, callback);
}
