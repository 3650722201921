let config = {
  autoblockingGates: [],
  registerAutoblockingGate: (gate) => { getConfig().autoblockingGates.push(gate) },
  autoblockingEvents: [],
};

export const getConfig = () => config;

export const getGates = () => getConfig().autoblockingGates

export const getRules = () => (config.configAutoblocking || {}).rules || [];

export const setConfig = (configToSet) => {
  config = {
    ...config,
    ...(configToSet || {}),
  };
  return getConfig();
}

export const trackAutoblock = (event) => { config.autoblockingEvents.push(event) }