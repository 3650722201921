export const CONTAINER_DATA_KEY = 'placeholderContainer';
export const CONTAINER_SELECTOR = '[data-placeholder-container]';

export const PLACEHOLDER_DATA_KEY = 'placeholder';
export const PLACEHOLDER_SELECTOR = `[data-${PLACEHOLDER_DATA_KEY}]`;
export const PLACEHOLDER_PARAMS_DATA_KEY = 'placeholderParams';

export const TEXT_DATA_KEY = 'text';
export const MTM_DATA_KEY = 'mtmKey';

export const ACTIVATED_DATA_KEY = 'placeholderActivated';
export const ACTIVATED_SELECTOR = `[data-placeholder-activated]`;

export function getContainer(iframe) {
	return iframe.closest(CONTAINER_SELECTOR);
}
