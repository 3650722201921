import { OUR_MIME_TYPE_SELECTOR,	getOrigin } from './domCompat';
import { log } from './utils'
import { getRules, getGates } from './config';

const matchTypes = {
	HOSTNAME: 'HOSTNAME',
	SELECTOR: 'SELECTOR',
};

const tagNames = [
	'IFRAME',
	'IMG',
	'EMBED',
	'VIDEO',
	'AUDIO',
	'PICTURE',
	'SOURCE',
	'SCRIPT',
]

const isAllowed = rule => {
	const gates = getGates();
  // Nodes with no config are allowed through
	return !rule ||
    // If there's a config, the config must pass EVERY registered gate
    (gates.length > 0 && gates.every(gate => gate(rule)));
}

export const shouldBlockTag = tagName => tagName && tagNames.indexOf(tagName.toUpperCase()) > -1;

const hostCache = {};
const hostMatches = (host, filter) => {
	hostCache[filter] = hostCache[filter] || new RegExp(filter);
	return hostCache[filter].test(host);
}

// Array polyfill is 10kB (i.e the same size as embed.js source code),
// so let's just make our own thing.
function arrayFind(array, predicate) {
	for (let idx = 0; idx < array.length; idx++) {
		const item = array[idx];
		if (predicate(item)) return item;
	}
}

/**
 * Gets a config rule where any of its `matches` matches a given predicate.
 * Note that if there are multiple rules for a given provider, and one of
 * them has a placeholder, the placeholder's (synthetic) policy id and slug \
 * are returned instead.
 *
 * This is because the API searches through the existing
 * micropolicies' thirdparties list and hydrates rules with real micropolicy
 * ids/slugs if the rule has no placeholder. This is to allow non-visual
 * autoblocking rules from being able to be consented to in the Cookie Widget.
 *
 * However, if there exist a corresponding rule that has placeholders (e.g.
 * facebook's SDK script and its actualy button div), the placeholder should win
 * since there now exist a way to provide consent on the page. This also ensures
 * that consent is given to the most specific micropolicy (the synth one),
 * whereas a customer micropolicy is typically the parent of a syth one, but
 * that relationship is impliciy (via the 34rd parties).
 */
function getConfigForMatch(predicate) {
	const rules = getRules();
	let matchFound;
	const config = arrayFind(rules, ({ matches }) => {
		const match = arrayFind(matches, predicate);
		matchFound = matchFound ?? match;
		return match;
	});
	let overrides;

	if (config && !config.placeholder) {
		const placeholderConfig = arrayFind(rules, (rule) =>
			rule !== config &&
			rule.placeholder &&
			rule.providerId === config.providerId,
		);
		if (placeholderConfig) {
			overrides = {
				policyId: placeholderConfig.policyId,
				policySlug: placeholderConfig.policySlug,
			};
		}
	}

	const maybeConfig = (overrides ? { ...config, ...overrides } : config)
	return maybeConfig && { ...maybeConfig, matchFound };
}

export const getConfigForRequest = (src) => {
	const origin = getOrigin(src)
	return getConfigForMatch(({ type, value }) =>
		type === matchTypes.HOSTNAME &&
		hostMatches(origin, value)
	);
}

export const getConfigForNode = (node) => {
	const srcOrigin = node.getAttribute('src') && getOrigin(node.getAttribute('src'));
	return getConfigForMatch(({ type, value }) => {
		switch(type) {
			case matchTypes.HOSTNAME:
				return srcOrigin && hostMatches(srcOrigin, value)
			case matchTypes.SELECTOR:
				return node.matches(value)
			default:
				return false;
		}
	});
}

export const shouldBlockRequest = (src, ctx) => {
	const requestConfig = src && getConfigForRequest(src)
	const shouldBlock = !isAllowed(requestConfig);

	log(`%cshouldBlockRequest? config - ${ctx}: %c${shouldBlock ? 'blocked' : 'allowed'}`, 'background: teal; color: black', `color: ${shouldBlock ? 'red' : 'green'}`, src, requestConfig)
	return shouldBlock;
}

export const shouldBlockNode = (node, ctx) => {
	if (!node || !node.tagName) {
		return false
	}

	if (node.mtmBlockedNode) {
		return false
	}
	if (node.matches(OUR_MIME_TYPE_SELECTOR) || node.closest(OUR_MIME_TYPE_SELECTOR)) {
		return false
	}

	const nodeConfig = getConfigForNode(node);
	const shouldBlock = !isAllowed(nodeConfig);
	log(`%cshouldBlockNode? config - ${ctx}: %c${shouldBlock ? 'blocked' : 'allowed'}`, 'background: blue; color: white', `color: ${shouldBlock ? 'red' : 'green'}`, node, nodeConfig)
	return shouldBlock;
}