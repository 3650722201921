import 'element-closest-polyfill';
import initAutoblocking, {
	getConfig,
	setConfig,
	getRules,
} from './modules/autoblocking'
import bootstrap from './bootstrap';
import { setupConfirmicActionBuffer } from './actionBuffer';
import makeTry from './makeTry';
/**
 * 🚨WARNING 🚨
 * This file kicks off the autoblocking logic, which needs to run ASAP as soon
 * as the file is loaded, and then loads in the embed. As such, it needs to
 * maintain to core principles:
 *
 * 1. It should be as fast / smol as possible
 * 2. It should not require any polyfills, since it is not run within an iframe
 *    and thus may interferer with the the host javascript.
 *
 * Once it is done kicking off the autoblocking code, it then creates an iframe
 * to download lazily the core Confirmic bundle, which is run within the safe space
 * of a same-origin (about:blank) iframe.
 */
function shouldAvoidRender() {
	// On some sites out side of our control, we may end up with embed.js getting
	// embed twice. In such a case, let's just bail out early.
	// See https://github.com/zeit/next.js/issues/9070
	if (window._mtm.didEmbed) return true;
	const ua = navigator && navigator.userAgent && navigator.userAgent.toLowerCase()
	if (!ua) return true
  return [
		'googleweblight',
		'googlebot',
		'apis-google',
		'mediapartners-google',
		'adsbot',
		'feedfetcher',
		'google-read-aloud',
		'bingbot',
		'msnbot',
		'bingpreview',
  ].some((scraper) => (ua.indexOf(scraper) !== -1));
}

function setupAutoblocking() {
	// Since the only way for bundle.js to have access to the config is via the
	// getParentWindow(), we store the mutated config on the window so that
	// it's reachable from within the bundle.js iframe.
	window._mtm = setConfig(window._mtm);

	if (!getRules().length) return;

	initAutoblocking();

}


(makeTry(() => {
	window._mtm = window._mtm || {};
	// Don't run on googlebot.
	if (!shouldAvoidRender()) {
		window._mtm.didEmbed = true;
		setupConfirmicActionBuffer(window);
		setupAutoblocking();

		const { projectId } = getConfig();
		if (projectId) window.Confirmic('load', { projectId });

		bootstrap()
	}
}, 'Embed script'))();